import React, { createContext, useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'

import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'
import { ReactComponent as ProfileIcon } from '@common/images/icons/v2/profile.svg'
import { ReactComponent as DotsIcon } from '@common/images/icons/v2/dots.svg'
import { ReactComponent as ArrowLeftIcon } from '@common/images/icons/v2/arrow-left.svg'

import SettingsNavigation from './components/settingsNavigation/SettingsNavigation'
import SideNavigation from './components/sideNavigation/SideNavigation'

import { MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'
import { staticRoutes } from '@routing/routes'

import styling from './bankingContainer.module.scss'

export const BankingContainerContext = createContext(null)

const BankingContainer = ({
  children,
  showProfileIcon = true,
  showHeaderFooter,
  isInvalidPath,
}) => {
  const currentTheme = localStorage.getItem('gw-theme')

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const [whiteCardContent, setWhiteCardContent] = useState(null)
  const [pageDetails, setPageDetails] = useState(null)
  // default to preset theme
  // if one doesn't exist, default to light theme
  const [theme, setTheme] = useState(currentTheme || 'light')

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light'
    localStorage.setItem('gw-theme', newTheme)
    setTheme(newTheme)
  }

  const title = useMemo(() => pageDetails?.title, [pageDetails])
  const subtext = useMemo(() => pageDetails?.subtext, [pageDetails])
  const showBackArrow = useMemo(() => pageDetails?.showBackArrow, [pageDetails])
  const showWhiteCard = useMemo(() => pageDetails?.showWhiteCard, [pageDetails])

  const isTablet = useMediaQuery({ query: `(max-width: ${MAX_WIDTH_BREAKPOINTS.MEDIUM_LARGE}px)` })

  const isSettings = useMemo(() => (
    pathname.includes('settings')
  ), [pathname])

  const handleProfileClick = useCallback(() => {
    if (!isTablet) {
      navigate(staticRoutes.settings.pathname)
    } else {
      setIsOpen(!isOpen)
    }
  }, [isTablet, navigate, isOpen])

  const titleContainerClasses = classNames(
    styling['title-container'],
    isSettings && styling['settings-title-container'],
    !subtext && !isTablet && styling['has-title-border']
  )

  const titleClasses = classNames(
    styling.title,
    subtext && styling['with-subtext']
  )

  const iconContainerClasses = classNames(
    styling['icon-container'],
    { [styling['profile-icon-container']]: isTablet || !showWhiteCard }
  )

  const contentClasses = classNames(
    styling['content-container'],
    showHeaderFooter && styling['with-header-footer']
  )

  const showLogo = useMemo(() => (
    isTablet && pathname === staticRoutes.dashboard.pathname
  ), [isTablet, pathname])

  return (
    <BankingContainerContext.Provider value={{ setWhiteCardContent, setPageDetails, theme, toggleTheme }}>
      <div
        className={
          classNames(
            styling['black-banking-container'],
            showHeaderFooter && styling['use-theme'],
            isInvalidPath && styling['invalid-path']
          )
        }
        data-theme={theme}
      >
        {showHeaderFooter ? (
          <>
            <SideNavigation isTablet={isTablet} />
            {isSettings && !isTablet && <SettingsNavigation />}
          </>
        ) : null}
        <div className={styling['main-container']}>
          {showHeaderFooter && (
            <div className={titleContainerClasses}>
              {showLogo ? <GreenwoodLogo className={styling.logo} /> : (
                <div className={titleClasses} onClick={() => navigate(-1)}>
                  {showBackArrow && (
                    <div className={styling['arrow-container']}>
                      <ArrowLeftIcon />
                      {!isTablet && !title && <span>Back</span>}
                    </div>
                  )}
                  <div className={styling['text-container']}>
                    <div>{title}</div>
                    {subtext && <div className={styling.subtext}>{subtext}</div>}
                  </div>
                </div>
              )}
              {showProfileIcon && !isSettings && !isTablet && (
                <div className={iconContainerClasses}>
                  {showWhiteCard ? (
                    <DotsIcon onClick={handleProfileClick} />
                  ) : (
                    <ProfileIcon onClick={handleProfileClick} />
                  )}
                </div>
              )}
              {showProfileIcon && isTablet && (
                <div className={iconContainerClasses}>
                  <ProfileIcon onClick={handleProfileClick} />
                </div>
              )}
            </div>
          )}
          <div className={contentClasses}>
            {children}
          </div>
          {isTablet && showHeaderFooter && (
            <SettingsNavigation
              onNavItemClick={() => setIsOpen(false)}
              isOpen={isOpen}
              closeNavMenu={() => setIsOpen(false)}
            />
          )}
      </div>
      {(!isTablet && showWhiteCard && showHeaderFooter) && (
        <div className={styling['white-card-container']}>
          {whiteCardContent}
        </div>
      )}
      </div>
    </BankingContainerContext.Provider>
  )
}

BankingContainer.propTypes = {
  children: PropTypes.any,
  showProfileIcon: PropTypes.bool,
  showHeaderFooter: PropTypes.bool,
  isInvalidPath: PropTypes.bool,
}

export default BankingContainer
