import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import AlertsManager from '@common/components/alertsManager/AlertsManager'
import BankingContainer from '../v2/bankingContainer/BankingContainer'

import { isAuthenticated, routeAuthFilter, useBraze } from '@common/utils'
import { staticRoutes } from '@routing/routes'
import { gql, useLazyQuery } from '@services/serviceUtils'
import { setCustomerAction } from '@redux/customer/customerActions'
import { removeAlertsAction } from '@redux/alerts/alertsActions'
import { isEqualIgnoreCase } from '@shared/utils'

import styling from './rootContainer.module.scss'

const RootContainer = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const customer = useSelector(state => state.customer)
  const isUserAuthenticated = useSelector(state => isAuthenticated(state))

  useBraze(isUserAuthenticated)

  const routeConfig = Object.values(staticRoutes).find(route => {
    // There can be multiple routes in staticRoutes with the same path.
    // If a match is found, return the one corresponding to the authenticated state.

    const pathMatch = route.pathname === location.pathname
    const authMatch = routeAuthFilter(route, isUserAuthenticated)

    return pathMatch && authMatch
  })

  const showHeaderFooter = useMemo(() => {
    if (isUserAuthenticated) {
      return routeConfig?.showHeaderFooter === undefined ? true : routeConfig?.showHeaderFooter
    } else {
      return false
    }
  }, [isUserAuthenticated, routeConfig])

  const [getCustomer, { data: customerData }] = useLazyQuery(gql`
    query Customer {
      customer {
        emailAddress
        firstName
        lastName
        phoneNumber
        customerStatus
        buildingNumber
        deliveryAddress
        locality
        subdivisions
        postalCode
      }
    }
  `)

  useEffect(() => {
    if (customerData) {
      const { __typename, ...customerDetails } = customerData.customer
      dispatch(setCustomerAction(customerDetails))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerData])

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search)
      // Note that all `params.get()` names must be lowercase
      if (isEqualIgnoreCase(params.get('verify'), 'waitlist')) {
        navigate(staticRoutes.signUpEmail.pathname, {
          state: {
            email: params.get('email'),
            waitlistCode: params.get('value'),
          },
        })
      } else if (isEqualIgnoreCase(params.get('verify'), 'email')) {
        navigate(staticRoutes.verifyEmail.pathname, {
          state: {
            sessionId: params.get('sessionId'),
            pin: params.get('value'),
            displayEmail: params.get('email'),
          },
        })
      }
    }
  }, [location, navigate])

  // Any time the pathname changes, update the page title if available
  useEffect(() => {
    const prefix = Object.values(staticRoutes).find(value => value.pathname === location.pathname)?.title
    let title = prefix ? `${prefix} - Greenwood` : 'Greenwood'

    // Since dashboard and splash share the same route, determine if the title should
    // be changed to the splash title instead
    if (location.pathname === staticRoutes.splash.pathname && !isUserAuthenticated) {
      title = `${staticRoutes.splash.title} - Greenwood`
    }

    document.title = title

    // populate customer data
    if (isUserAuthenticated && isEmpty(customer)) {
      getCustomer()
    }
  }, [location.pathname, isUserAuthenticated, customer, getCustomer])

  /* Any time the pathname changes, clear any exising alerts except for
     alerts that auto-dismiss */
  useEffect(() => {
    dispatch(removeAlertsAction({ excludeAutoDismiss: true }))
  }, [location.pathname, dispatch])

  return (
    <div className={styling['root-container']}>
      <AlertsManager />
      <BankingContainer showHeaderFooter={showHeaderFooter} isInvalidPath={!isUserAuthenticated && !routeConfig}>
        {children}
      </BankingContainer>
    </div>
  )
}

RootContainer.propTypes = {
  children: PropTypes.node,
  isAuthenticated: PropTypes.bool,
}

export default RootContainer
