import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styling from './imageRightButtonCallout.module.scss'

const ImageRightButtonCallout = ({ onClick, title, subtext, backgroundImage, className }) => {
  return (
    <div
      className={classNames(styling['right-image-button-callout-container'], className && className)}
      role='button'
      onClick={onClick}
    >
      <div className={styling['text-container']}>
        <p>{title}</p>
        <p className={styling.subtext}>{subtext}</p>
      </div>
      {backgroundImage ? (
        <div className={styling['image-container']} style={{ backgroundImage: `url(${backgroundImage})` }} />
      ) : null}
    </div>
  )
}

ImageRightButtonCallout.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  subtext: PropTypes.string,
  backgroundImage: PropTypes.string,
  className: PropTypes.string,
}

export default ImageRightButtonCallout
