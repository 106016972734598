import TheHiddenGymLogo from '../images/v2/cashback/the-hidden-gym/the-hidden-gym-logo.png'
import TheHiddenGymImage1 from '../images/v2/cashback/the-hidden-gym/the-hidden-gym-1.png'
import TheHiddenGymImage2 from '../images/v2/cashback/the-hidden-gym/the-hidden-gym-2.png'
import TheHiddenGymImage1Lg from '../images/v2/cashback/the-hidden-gym/the-hidden-gym-1-lg.png'
import TheHiddenGymImage2Lg from '../images/v2/cashback/the-hidden-gym/the-hidden-gym-2-lg.png'

import KitchenCrayLogo from '../images/v2/cashback/kitchen-cray/kitchen-cray-logo.png'
import KitchenCrayImage1 from '../images/v2/cashback/kitchen-cray/kitchen-cray-1.png'
import KitchenCrayImage2 from '../images/v2/cashback/kitchen-cray/kitchen-cray-2.png'
import KitchenCrayImage1Lg from '../images/v2/cashback/kitchen-cray/kitchen-cray-1-lg.png'
import KitchenCrayImage2Lg from '../images/v2/cashback/kitchen-cray/kitchen-cray-2-lg.png'
import KitchenCrayBg from '../images/v2/cashback/kitchen-cray/kitchen-cray-bg.png'

import CafeBartiqueBg from '../images/v2/cashback/cafe-bartique/cafe-bartique-bg.png'
import CafeBartiqueLogo from '../images/v2/cashback/cafe-bartique/cafe-bartique-logo.png'
import CafeBartiqueImage1 from '../images/v2/cashback/cafe-bartique/cafe-bartique-1.png'
import CafeBartiqueImage2 from '../images/v2/cashback/cafe-bartique/cafe-bartique-2.png'
import CafeBartiqueImage1Lg from '../images/v2/cashback/cafe-bartique/cafe-bartique-1-lg.png'
import CafeBartiqueImage2Lg from '../images/v2/cashback/cafe-bartique/cafe-bartique-2-lg.png'

export const cashbackRetailers = [
  {
    name: 'The Hidden Gym',
    details:
      "Unlock your hidden you at The Hidden Gym. Owners, Rashad and Tosha McDade are the founders and co-owners of The Hidden Gym and have a collective 25 years of fitness and health experience. With an energetic and fun approach to achieving their clients' health goals, they are known for pushing their clients to their full potential through quality programming and meal planning! A dynamic duo, the husband and wife team put their focus on an overall healthy lifestyle including healthy eating, positive energy, and encouraging annual check-up and screenings.",
    address: {
      street: '2011 Bolton Road NW, Suite 100',
      city: 'Atlanta',
      state: 'GA',
      zipCode: '30318',
    },
    phone: '(678) 557-6005',
    url: 'https://thehgatl.com/',
    key: 'the-hidden-gym',
    logo: TheHiddenGymLogo,
    background: {
      image: TheHiddenGymImage1,
      color: 'linear-gradient(250deg, rgba(14, 152, 216, 0.82) 1.21%, rgba(9, 129, 184, 0.82) 98.18%)',
    },
    images: (isMobile) => {
      if (isMobile) {
        return [
          <img src={TheHiddenGymImage1} alt='the hidden gym owners' />,
          <img src={TheHiddenGymImage2} alt='the hidden gym logo' />,
        ]
      } else {
        return [
          <img src={TheHiddenGymImage1Lg} alt='the hidden gym owners' />,
          <img src={TheHiddenGymImage2Lg} alt='the hidden gym logo' />,
        ]
      }
    },
  }, {
    name: 'Kitchen Cray',
    details:
      "Brunch isn't a meal, it's a lifestyle. Kitchen Cray ATL Restaurant, a vibrant brunch spot in Sandy Springs, GA, serving up delectable dishes and craft cocktails with a side of excellent service and friendly staff. Our menu features mouthwatering options like avocado toast, blackened salmon, crab cakes, and more, alongside refreshing drinks like Bloody Marys, mimosas, and shaken cocktails. Whether you're indulging in our french toast or savoring our lamb chops, our focus on quality and flavor shines through. Join us for live music or a live DJ and elevate your dining experience in our stylish space. Step into Kitchen Cray ATL and let us redefine your brunch experience.",
    address: {
      street: '8540 Roswell Rd',
      city: 'Sandy Springs',
      state: 'GA',
      zipCode: '30350',
    },
    phone: '(678) 580-2210',
    url: 'https://kitchencrayatlanta.com/',
    key: 'kitchen-cray',
    logo: KitchenCrayLogo,
    background: {
      image: KitchenCrayBg,
      color: 'linear-gradient(250deg, rgba(43, 41, 53, 0.7) 1.21%, rgba(43, 41, 53, 0.7) 98.18%)',
    },
    images: (isMobile) => {
      if (isMobile) {
        return [
          <img src={KitchenCrayImage1} alt='kitchen cray logo' />,
          <img src={KitchenCrayImage2} alt='kitchen cray french toast' />,
        ]
      } else {
        return [
          <img src={KitchenCrayImage1Lg} alt='kitchen cray logo' />,
          <img src={KitchenCrayImage2Lg} alt='kitchen cray french toast' />,
        ]
      }
    },
  }, {
    name: 'Cafe Bartique',
    details:
      "Delicious food for you. Cafe Bartique provides the warmth and comfort of Southern hospitality while pioneering a modern coffee experience. Our mission is to curate a haven where every sip tells a story, and every interaction feels like a warm embrace. Our commitment to sustainability extends beyond our beverages, as we actively seek ways to minimize our environmental footprint and give back to the community that has embraced us. At Cafe Bartique we believe that every cup tells a story, and every patron becomes part of the narrative of our vibrant, welcoming community. We are dedicated to preserving our local community's rich history while fostering a sense of belonging. We are a cultural hub, a gathering place, a catalyst for positive change, and a soundboard for creative innovation and expression.",
    address: {
      street: '2315 Cascade Rd SW',
      city: 'Atlanta',
      state: 'GA',
      zipCode: '30311',
    },
    phone: '(404) 343-1780',
    url: 'https://cafebartique.com/',
    key: 'cafe-bartique',
    logo: CafeBartiqueLogo,
    background: {
      image: CafeBartiqueBg,
      color: 'linear-gradient(250deg, rgba(14, 152, 216, 0.40) 1.21%, rgba(9, 129, 184, 0.40) 98.18%)',
    },
    images: (isMobile) => {
      if (isMobile) {
        return [
          <img src={CafeBartiqueImage1} alt='cafe bartique logo' />,
          <img src={CafeBartiqueImage2} alt='cafe bartique interior' />,
        ]
      } else {
        return [
          <img src={CafeBartiqueImage1Lg} alt='cafe bartique logo' />,
          <img src={CafeBartiqueImage2Lg} alt='cafe bartique interior' />,
        ]
      }
    },
  },
]
