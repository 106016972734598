import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from '@shared/components/button/Button'

import styling from './cashbackOfferCard.module.scss'

const CashbackOfferCard = ({ className, handleClick = () => {}, header, subtext, logo, style }) => {
  return (
    <div className={classNames(styling['cashback-offer-card'], className && className)} style={style}>
      {logo ? (
        <div className={styling['logo-container']}>
          <img src={logo} alt={`${header} logo`} />
        </div>
      ) : null}
      <div>
        <h3>{header}</h3>
        <div>
          <p>{subtext}</p>
          <Button className={styling['view-more-btn']} onClick={handleClick}>View More</Button>
        </div>
      </div>
    </div>
  )
}

CashbackOfferCard.propTypes = {
  className: PropTypes.string,
  handleClick: PropTypes.func,
  header: PropTypes.string,
  subtext: PropTypes.string,
  logo: PropTypes.string,
  style: PropTypes.object,
}

export default CashbackOfferCard
