import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'
import { useFlags } from 'launchdarkly-react-client-sdk'

import FaqCallout from './components/faqCallout/FaqCallout'
import CashbackOfferCard from './components/cashbackOfferCard/CashbackOfferCard'

import { MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'
import { BankingContainerContext } from '@common/components/v2/bankingContainer/BankingContainer'
import { staticRoutes } from '@routing/routes'

import styling from './cashback.module.scss'

const Cashback = () => {
  const [title, setTitle] = useState('')

  const navigate = useNavigate()

  const isTablet = useMediaQuery({ query: `(max-width: ${MAX_WIDTH_BREAKPOINTS.MEDIUM_LARGE}px)` })

  const { cashBackForBuyingBlack } = useFlags()

  const { setPageDetails } = useContext(BankingContainerContext)

  const { pathname } = useLocation()

  const isMainPage = useMemo(() => pathname === staticRoutes.cashback.pathname, [pathname])

  useEffect(() => {
    if (cashBackForBuyingBlack === false) {
      navigate(staticRoutes.dashboard.pathname)
    }
  }, [cashBackForBuyingBlack, navigate])

  useEffect(() => {
    if (isMainPage) {
      setTitle('Cash Back for Buying Black')
    }
  }, [isMainPage, setTitle])

  useEffect(() => {
    let pageTitle = ''

    if (isTablet) {
      pageTitle = isMainPage ? 'Business Directory' : 'Offer'
    }

    setPageDetails({
      title: pageTitle,
      showBackArrow: true,
    })

    return () => {
      setPageDetails(null)
    }
  }, [setPageDetails, isTablet, isMainPage])

  const containerClasses = classNames(
    'v2-page-content-container',
    styling['cashback-container'],
    isMainPage && styling.main
  )

  return (
    <div className={containerClasses}>
      {!isTablet && (
        <div className='title-container'>
          <h2>{title}</h2>
          <p>Earn up to 10% cash back on all Greenwood debit cards.</p>
        </div>
      )}
      <div className={styling['content-container']}>
        <Outlet context={{ title, setTitle }} />
        {(!isTablet || isMainPage) ? (
          <div className={styling['join-us-container']}>
            <h3 className={classNames('v2-header', styling.header)}>Join Us</h3>
            <div>
              <CashbackOfferCard
                className={styling['join-us-cta']}
                header={'Get rewarded for joining our cash back program'}
                subtext='Help your business grow by offering customers amazing cashback deals.'
                handleClick={() => window.open('https://form.typeform.com/to/AtBxhg1k')}
              />
              {isTablet ? <FaqCallout /> : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default Cashback
