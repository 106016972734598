import React from 'react'
import { useMediaQuery } from 'react-responsive'

import LightBulbImage from '@common/images/v2/lightbulb.png'
import { ReactComponent as GreenwoodLogo } from '@shared/images/greenwood-logo.svg'

import ImageRightButtonCallout from '@common/components/callout/imageRightButtonCallout/ImageRightButtonCallout'
import ExternalLink from '@common/components/link/ExternalLink'

import { MAX_WIDTH_BREAKPOINTS } from '@shared/constants/uiConstants'

import styling from './faqCallout.module.scss'

const FaqCallout = () => {
  const isTablet = useMediaQuery({ query: `(max-width: ${MAX_WIDTH_BREAKPOINTS.MEDIUM_LARGE}px)` })

  return (
    <div className={styling['faq-cta-container']}>
      <ImageRightButtonCallout
        onClick={() => window.open('https://www.gogreenwood.com/faq')}
        title='FAQ'
        subtext='Find answers to your most common questions and learn more about how our program works.'
        backgroundImage={LightBulbImage}
      />
      <ExternalLink
        to={'https://www.gogreenwood.com/cashback-terms-and-conditions'}
        className='underlined-link'
      >
        Terms & Conditions
      </ExternalLink>
      {isTablet && <GreenwoodLogo />}
    </div>
  )
}

export default FaqCallout
