import React, { useContext, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import { useMediaQuery } from 'react-responsive'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { ReactComponent as AddMoneyIcon } from '@common/images/icons/v2/coins-hand.svg'
import { ReactComponent as ManageMoneyIcon } from '@common/images/icons/v2/credit-card-search.svg'
import CartImage from '@common/images/v2/cashback-cart.png'

import DashboardCtas from './components/dashboardCtas/DashboardCtas'
import AccountCardsList from './components/accountCardsList/AccountCardsList'
import InvestStockCampaignModal from './components/investStockCampaignModal/InvestStockCampaignModal'
import ApplicationStatusDisplay from './components/applicationStatusDisplay/ApplicationStatusDisplay'
import RecentTransactions from '@common/components/v2/recentTransactions/RecentTransactions'
import QuickActionModal from '@common/components/v2/quickActionModal/QuickActionModal'
import AppLinks from '@common/components/callout/AppLinks'
import LoadingContainer from '@shared/components/loadingContainer/LoadingContainer'
import Modal from '@shared/components/modal/Modal'
import ModalBody from '@shared/components/modal/ModalBody'
import ModalHeader from '@shared/components/modal/ModalHeader'
import ImageRightButtonCallout from '@common/components/callout/imageRightButtonCallout/ImageRightButtonCallout'

import { BankingContainerContext } from '@common/components/v2/bankingContainer/BankingContainer'

import { gql, useLazyQuery, useQuery } from '@services/serviceUtils'
import {
  setApplicationAction,
  resetLocationState,
  setOnboardingFlowTypeAction,
} from '@redux/application/applicationActions'
import { setCustomerAction } from '@redux/customer/customerActions'
import { resetTransferDetailsAction } from '@redux/transferDetails/transferDetailsActions'
import { resetDebitCardDepositDetailsAction } from '@redux/debitCardDepositDetails/debitCardDepositDetailsActions'
import { addAlertAction, removeAlertsAction } from '@redux/alerts/alertsActions'
import { staticRoutes } from '@routing/routes'
import { hasAccountType, showCta } from '@common/utils/accountUtils'
import {
  MAX_WIDTH_BREAKPOINTS,
  APPLICATION_STATUSES,
  ALERT_TYPES,
} from '@shared/constants/uiConstants'
import { CTA_TYPES, PRODUCT_TYPES, INVEST_PROMOTIONS } from '@common/constants'
import {
  SEGMENT_PAGE_NAMES,
  trackPage,
} from '@common/utils'

import styling from './dashboard.module.scss'

const getAccountById = (accounts, id) => accounts?.find(account => account.id === id)

const dashboardDataQuery = gql`
  query Dashboard {
    application {
      userId
      submittedAt
      customerApplicationStatus
      onboardingFlowType
      referenceId
    }
    accounts {
      totalBalance {
        amount
      }
      accounts {
        id
        balance {
          amount
        }
        accountType
        title
        accountNumber
        transactions {
          amount {
            amount
          }
          description
          details {
            label
            value
          }
          transactionTime
          transactionType
          isSettled
        }
      }
    }
    ctas {
      callToActionType
      priority
    }
  }
`

const subscriptionsQuery = gql`
  query Subscriptions {
    subscriptions {
      id
    }
  }
`

const investingAccountsQuery = gql`
  query InvestingAccounts {
    investingAccounts {
      investingPromotions {
        promotionCode
      }
      userEligible
      accountNumber
    }
  }
`

const investingPromotionsQuery = gql`
  query InvestingPromotions {
    investingPromotions {
      promotionCode
    }
  }
`

const fetchCardQuery = gql`
  query Card {
    card {
      last4
      message
      cardState
      availableOperations
      reportingOptions {
        caption
        cardStatus
      }
    }
  }
`

const Dashboard = () => {
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [showDownloadAppModal, toggleShowDownloadAppModal] = useState(false)
  const [showInvestStockCampaignModal, toggleShowInvestStockCampaignModal] = useState(false)
  const [showQuickActionModal, toggleShowQuickActionModal] = useState(false)
  const [quickActionType, setQuickActionType] = useState(null)
  const [cookies, setCookie] = useCookies(['hasSeenDownloadAppModal', 'hasSeenInvestPromoModal'])

  const isTablet = useMediaQuery({ query: `(max-width: ${MAX_WIDTH_BREAKPOINTS.MEDIUM_LARGE}px)` })

  const { cashBackForBuyingBlack } = useFlags()

  const cookieOptions = useMemo(() => (
    {
      path: '/',
      secure: true,
    }
  ), [])

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { setPageDetails } = useContext(BankingContainerContext)

  const customer = useSelector(state => state.customer)
  const pollingHasMaxedOut = useSelector(state => state?.application?.pollingHasMaxedOut)
  const showMfaComingSoonBanner = useSelector(state => state.auth?.showMfaComingSoonBanner)

  const getInvestPromoCodes = arr => (
    arr?.map(promo => promo?.promotionCode)
  )

  // Don't cache, the account and CTA data must be up-to-date on each visit of the dashboard
  const { loading, error, data } = useQuery(dashboardDataQuery, { fetchPolicy: 'no-cache' })
  const { data: subscriptionsData } = useQuery(subscriptionsQuery, {
    fetchPolicy: 'no-cache',
    onError: () => dispatch(removeAlertsAction()),
  })
  const { data: investingAccountsData } = useQuery(investingAccountsQuery, {
    fetchPolicy: 'no-cache',
    onError: () => dispatch(removeAlertsAction()),
  })

  const { data: investingPromotionsData } = useQuery(investingPromotionsQuery, {
    fetchPolicy: 'no-cache',
    onError: () => dispatch(removeAlertsAction()),
  })

  const [fetchCard, { data: cardData, error: cardError }] = useLazyQuery(fetchCardQuery, {
    fetchPolicy: 'cache-and-network',
  })

  const hasPremium = useMemo(() => customer?.hasPremium, [customer?.hasPremium])
  const hasElevate = useMemo(() => customer?.hasElevate, [customer?.hasElevate])
  const isStandardAccount = useMemo(() => (
    data?.application?.customerApplicationStatus === APPLICATION_STATUSES.PASS && !hasPremium && !hasElevate
  ), [hasPremium, hasElevate, data?.application?.customerApplicationStatus])

  const activePromotions = useMemo(
    () => getInvestPromoCodes(investingPromotionsData?.investingPromotions),
    [investingPromotionsData?.investingPromotions]
  )

  const showInvestPromoCallout = useMemo(() => {
    const userInvestingAccount = investingAccountsData?.investingAccounts[0]

    if (userInvestingAccount?.userEligible) {
      const investingPromotions = getInvestPromoCodes(userInvestingAccount?.investingPromotions)
      return investingPromotions?.includes(INVEST_PROMOTIONS.INVEST_STOCK_PROMO)
    }

    return false
  }, [investingAccountsData?.investingAccounts])

  const showExploreInvestCallout = useMemo(() => {
    const userInvestingAccount = investingAccountsData?.investingAccounts[0]

    return userInvestingAccount?.userEligible && !userInvestingAccount?.accountNumber
  }, [investingAccountsData])

  const showSelectDdaTypeCta = useMemo(() => (
    showCta({
      ctas: data?.ctas,
      ctaType: CTA_TYPES.SELECT_DDA_TYPE,
    })
  ), [data?.ctas])

  useEffect(() => {
    setPageDetails({ title: 'Banking' })

    return () => {
      setPageDetails(null)
    }
  }, [setPageDetails])

  useEffect(() => {
    if (data?.accounts?.accounts?.length) {
      fetchCard()
    }
  }, [fetchCard, data?.accounts?.accounts?.length])

  /* Track visits to dashboard when account is approved (other statuses are captured in
     ApplicationStatusDisplay.js) */
  useEffect(() => {
    if (
      data?.application?.customerApplicationStatus &&
      data?.application?.customerApplicationStatus === APPLICATION_STATUSES.PASS
    ) {
      trackPage({ name: SEGMENT_PAGE_NAMES.DASHBOARD_HOME })
    }
  }, [data])

  useEffect(() => {
    setSelectedAccount(data?.accounts?.accounts?.[0]?.id || null)

    if (data?.application) {
      // Add application data to redux for use in other components, such as the header
      dispatch(setApplicationAction(data.application))

      if (data?.application?.onboardingFlowType) {
        // Save the user's onboardingFlowType in state
        dispatch(setOnboardingFlowTypeAction(data.application.onboardingFlowType))
      }

      const applicationPassed =
        data.application.customerApplicationStatus === APPLICATION_STATUSES.PASS

      /* When the CTAs load show either the DDA selection page, the premium upgrade CTA
           modal once, or the download mobile app modal once */
      if (data.ctas) {
        if (showSelectDdaTypeCta && applicationPassed) {
          navigate(staticRoutes.selectAccountTier.pathname, { replace: true })
        } else if (
          applicationPassed &&
          cookies?.hasSeenDownloadAppModal !== data.application.userId &&
          (!activePromotions?.length ||
            cookies?.hasSeenInvestPromoModal === data.application.userId ||
            !showInvestPromoCallout)
        ) {
          toggleShowDownloadAppModal(true)
          setCookie('hasSeenDownloadAppModal', data.application.userId, cookieOptions)
        }
      }
    }

    // Add account data to redux for use in other components, such as the header
    if (data?.accounts?.accounts?.length) {
      const accounts = data.accounts.accounts
      const isPremiumAccount =
        hasAccountType({ accounts, accountType: PRODUCT_TYPES.premium }) ||
        hasAccountType({ accounts, accountType: PRODUCT_TYPES.premiumPlus })
      const isElevateAccount = hasAccountType({ accounts, accountType: PRODUCT_TYPES.elevateCard })

      dispatch(
        setCustomerAction({
          hasPremium: isPremiumAccount,
          hasElevate: isElevateAccount,
          hasPrimaryAccount: true,
        })
      )
    } else {
      dispatch(setCustomerAction({ hasPrimaryAccount: false }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    const hasInvestCampaignEligibility = typeof customer?.hasElevate === 'boolean' && isStandardAccount
      ? activePromotions?.includes(INVEST_PROMOTIONS.INVEST_STOCK_PROMO)
      : showInvestPromoCallout

    if (
      data?.application?.userId &&
      hasInvestCampaignEligibility &&
      cookies?.hasSeenInvestPromoModal !== data?.application?.userId
    ) {
      toggleShowInvestStockCampaignModal(true)
      setCookie('hasSeenInvestPromoModal', data.application.userId, cookieOptions)
    }
  }, [
    isStandardAccount,
    activePromotions,
    showInvestPromoCallout,
    cookies?.hasSeenInvestPromoModal,
    setCookie,
    data?.application?.userId,
    cookieOptions,
    customer?.hasElevate,
  ])

  useEffect(() => {
    // Set boolean value for subscription users
    if (subscriptionsData?.subscriptions?.length) {
      dispatch(setCustomerAction({ hasSubscription: true }))
    } else {
      dispatch(setCustomerAction({ hasSubscription: false }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionsData])

  useEffect(() => {
    /* reset the details of the transfer and debit card deposit
       when user first arrives on the Dashboard */
    dispatch(resetTransferDetailsAction())
    dispatch(resetDebitCardDepositDetailsAction())
    /* reset the location state details when user first arrives on the Dashboard */
    dispatch(resetLocationState())
  }, [dispatch])

  useEffect(() => {
    /* Only show this alert if the showMfaComingSoonBanner is set to true.
       Do not show the alert if the user has already seen it once during this session. */
    const userId = data?.application?.userId

    if (
      userId &&
      showMfaComingSoonBanner &&
      window.sessionStorage.getItem('hasSeenMfaComingSoonBanner') !== userId
    ) {
      dispatch(
        addAlertAction({
          dismissible: true,
          type: ALERT_TYPES.WARNING,
          text:
            'Multi-Factor Authentication coming soon! Please ensure your email and phone number are up to date.',
        })
      )

      window.sessionStorage.setItem('hasSeenMfaComingSoonBanner', userId)
    }
  }, [data, showMfaComingSoonBanner, dispatch])

  const selectedAccountData = useMemo(() => (
    getAccountById(data?.accounts?.accounts, selectedAccount)
  ), [data?.accounts?.accounts, selectedAccount])

  const isApproved = useMemo(() => (
    data?.application?.customerApplicationStatus === APPLICATION_STATUSES.PASS
  ), [data?.application?.customerApplicationStatus])

  const handleQuickActionClick = type => {
    setQuickActionType(type)
    toggleShowQuickActionModal(true)
  }

  const getActionContainer = ({ icon, title, onClick }) => (
    <div className={styling['action-container']}>
      <div
        className={styling['action-container-btn']}
        role='button'
        onClick={onClick}
      >
        {icon}
        {!isTablet && <p>{title}</p>}
      </div>
      {isTablet && <p>{title}</p>}
    </div>
  )

  const accountContainerClasses = classNames(
    styling['account-container'],
    { [styling['inactive-account']]: !isApproved }
  )

  return (
    <>
      <div className={styling.page} data-cy='authenticated-loaded-view'>
        <LoadingContainer error={error} errorMessage='Error loading accounts.' loading={loading}>
          {data && (
            <>
              <div className={accountContainerClasses}>
                <ApplicationStatusDisplay
                  status={data?.application?.customerApplicationStatus}
                  applicationData={data?.application}
                  pollingHasMaxedOut={pollingHasMaxedOut}
                />
                {isApproved && (
                  <>
                    <AccountCardsList
                      ctas={data?.ctas}
                      cardDetails={cardData?.card}
                      openModal={type => handleQuickActionClick(type)}
                      accounts={data?.accounts?.accounts}
                      setSelectedAccount={id => setSelectedAccount(id)}
                      hasError={cardError || error}
                    />
                    {!isTablet && <h3 className='v2-header'>Quick Actions</h3>}
                    <div className={styling['quick-actions']}>
                      {getActionContainer({
                        title: 'Add Money',
                        icon: <AddMoneyIcon className={styling['add-money-icon']} />,
                        onClick: () => handleQuickActionClick('add'),
                      })}
                      {getActionContainer({
                        title: 'Manage Money',
                        icon: <ManageMoneyIcon />,
                        onClick: () => handleQuickActionClick('manage'),
                      })}
                    </div>
                    <div className={styling['cashback-transactions-container']}>
                      {cashBackForBuyingBlack && (
                        <ImageRightButtonCallout
                          onClick={() => navigate(staticRoutes.cashback.pathname)}
                          title='Cash Back for Buying Black'
                          subtext='Support participating Black-owned businesses and earn up to 10% cash back!'
                          backgroundImage={CartImage}
                        />
                      )}
                      <RecentTransactions
                        accountId={selectedAccountData?.id}
                        title={selectedAccountData?.title}
                        transactions={selectedAccountData?.transactions}
                        isDashboardView
                      />
                    </div>
                  </>
                )}
              </div>
              <div className={styling['cta-container']}>
                <DashboardCtas
                  ctas={data?.ctas}
                  isStandardAccount={isStandardAccount}
                  activePromotions={activePromotions}
                  showInvestPromoCallout={showInvestPromoCallout}
                  showExploreInvestCallout={showExploreInvestCallout}
                />
              </div>
            </>
          )}
        </LoadingContainer>
      </div>
      <Modal
        centered
        size='md'
        open={showDownloadAppModal}
        toggleModal={() => toggleShowDownloadAppModal(!showDownloadAppModal)}
      >
        <ModalHeader withCloseButton closeModal={() => toggleShowDownloadAppModal(false)}>
          <h4 data-cy='download-app-header'>Download the Greenwood app!</h4>
        </ModalHeader>
        <ModalBody>
          <p className={styling['do-not-miss']}>
            Manage your accounts and take advantage of other features.
          </p>
          <AppLinks />
        </ModalBody>
      </Modal>
      <InvestStockCampaignModal
        isOpen={showInvestStockCampaignModal}
        toggleModal={() => toggleShowInvestStockCampaignModal(!showInvestStockCampaignModal)}
        closeModal={() => toggleShowInvestStockCampaignModal(false)}
        isUpgrading={isStandardAccount}
      />
      <QuickActionModal
        isOpen={showQuickActionModal}
        toggleModal={() => toggleShowQuickActionModal(!showQuickActionModal)}
        closeModal={() => toggleShowQuickActionModal(false)}
        quickActionType={quickActionType}
        availableCardOperations={cardData?.card?.availableOperations}
        activateCardCallback={() => fetchCard()}
      />
    </>
  )
}

export default Dashboard
