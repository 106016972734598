import React, { useContext, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import MembershipView from './MembershipView'
import AboutMyMembership from './AboutMyMembership'
import ChangeMyMembership from './ChangeMyMembership'
import PaymentMethod from './PaymentMethod'
import ProtectedRoute from '@routing/ProtectedRoute'

import { BankingContainerContext } from '@common/components/v2/bankingContainer/BankingContainer'

import { staticRoutes } from '@routing/routes'

import styling from '../settings/settings.module.scss'

const Membership = () => {
  const { setPageDetails } = useContext(BankingContainerContext)

  useEffect(() => {
    setPageDetails({ title: 'Membership', showBackArrow: true })

    return () => {
      setPageDetails(null)
    }
  }, [setPageDetails])

  return (
    <div className={styling['tabs-content-container']}>
      <Routes>
        <Route
          path={staticRoutes.settingsMembershipDetails.relativePathname}
          element={<ProtectedRoute component={MembershipView} />}
        />
        <Route
          path={staticRoutes.settingsMembershipAboutMyMembership.relativePathname}
          element={<ProtectedRoute component={AboutMyMembership} />}
        />
        <Route
          path={staticRoutes.settingsMembershipChangeMyMembership.relativePathname}
          element={<ProtectedRoute component={ChangeMyMembership} />}
        />
        <Route
          path={staticRoutes.settingsMembershipPaymentMethod.relativePathname}
          element={<ProtectedRoute component={PaymentMethod} />}
        />
        <Route path='*' element={<Navigate to={staticRoutes.settingsMembershipDetails.pathname} />} />
      </Routes>
    </div>
  )
}

export default Membership
